import { Button, Pagination, Table } from "antd";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
import { Link } from "react-router-dom";

const TableComponent = ({
	columns,
	list,
	allList,
	total,
	loading,
	csvFileName,
	scrollx,
	paginatedThunk,
	deleteManyThunk,
	children,
	query,
	priceUpdate,
	showDraftBtn
}) => {
	// const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const dispatch = useDispatch();

	// const onSelectChange = (newSelectedRowKeys) => {
	//   setSelectedRowKeys(newSelectedRowKeys);
	// };
	// const rowSelection = {
	//   selectedRowKeys,
	//   onChange: onSelectChange,
	// };

	
	const updateList = allList?.map((item, index)=>{
		return {
				slNo: index + 1,
				systemID: item?.id,
				sku: item?.sku,
				name: item?.name,
				newPrice: '',
				previousPrice: item?.productSalePrice,
				QTY: item?.productQuantity,
				lastUpdatedAt: item?.updatedAt?.slice(0, 10)
				
		}
	})
	const fetchData = (page, count) => {
		dispatch(paginatedThunk({ ...query, status: true, page, count: count }));
	};

	// column select
	const [columnsToShow, setColumnsToShow] = useState([]);
	const columnsToShowHandler = (val) => {
		setColumnsToShow(val);
	};

	useEffect(()=>{
		setColumnsToShow(columns);
	},[columns]);

	return (
		<>
			<div className='mt-2'>
				<div className='pb-3'>
					<div className='w-full dark:text-yellow-50 flex flex-col md:flex-row gap-2 items-center justify-between'>
						<div className='flex gap-2'>
							{/* {!!selectedRowKeys.length && (
                <>
                  <BulkDelete
                    setSelected={setSelectedRowKeys}
                    selected={selectedRowKeys}
                    updateThunk={deleteManyThunk}
                    loadThunk={paginatedThunk}
                  />
                </>
              )} */}
							{showDraftBtn &&
								<Link to={"/admin/sale/draft"}>
									<Button className='px-4 py-1 h-[34px] bg-black/80 text-white border rounded-md text-white text-xs  md:text-base rounded'>
										Drafts
									</Button>
								</Link>
							}

							{allList &&
								<div className='px-4 py-1 bg-black/80 text-white border rounded-md'>
									<CSVLink
										data={allList ? allList : ""}
										className='text-white text-xs  md:text-base  py-1 px-0 rounded mr-2 '
										// style={{
										//   marginTop: "5px",
										// }}
										filename={csvFileName}>
										Download CSV
									</CSVLink>
								</div>
							}
							{ priceUpdate?
							<div className='px-4 py-1 bg-black/80 text-white border rounded-md'>
							 <CSVLink
									data={updateList ? updateList : ""}
									className='text-white text-xs  md:text-base  py-1 px-0 rounded mr-2 '
									// style={{
									//   marginTop: "5px",
									// }}
									filename={'price update'}>
									Sale Price Update
								</CSVLink>
								</div>
								: null}	
							<ColVisibilityDropdown
								options={columns}
								columns={columns}
								columnsToShowHandler={columnsToShowHandler}
								localKey={csvFileName}
							/>
						</div>

						<div className=''>
							{total >= 1 && (
								<Pagination
									total={total}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total} items`
									}
									onChange={fetchData}
									defaultPageSize={10}
									defaultCurrent={1}
									showSizeChanger={total > 10}
								/>
							)}
						</div>
					</div>
				</div>

				<Table
					loading={loading}
					// rowSelection={rowSelection}
					columns={columnsToShow}
					dataSource={
						!!list?.length && list.map((item) => ({ ...item, key: item?.id }))
					}
					pagination={false}
					scroll={{ x: scrollx || 1800, y: window.innerHeight - 319 }}
				/>
			</div>
			{children && children}
		</>
	);
};
export default TableComponent;
