import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator, { commonQueryGenerator } from "../../../../utils/queryGenarator";

const initialState = {
  list: null,
  total: null,
  information: null,
  purchase: null,
  error: "",
  loading: false,
  purchaseListByProductsLoading: false,
  purchaseListByProducts: null,
  purchaseListByProductsError: ''
};

export const addPurchase = createAsyncThunk(
  "purchase/addPurchase",
  async (values) => {
    try {
      const { data } = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `purchase-invoice/`,
        data: {
          ...values,
        },
      });

      toast.success("New Product Purchased");
      const respData = {
        newData: {
          ...data.createdInvoice,
          supplier: data.supplier,
        },
        createdInvoiceId: data.createdInvoice.id,
        message: "success",
      };

      return respData;
    } catch (error) {
      console.log(error.message);
      return {
        message: "error",
      };
    }
  }
);

export const updatePurchase = createAsyncThunk("sale/updatePurchase", async ({ values, purchaseId }) => {
  try {
    const { data } = await axios({
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `purchase-invoice/${purchaseId}`,
      data: {
        ...values,
      },
    });

    const respData = {
      udpatedData: {
        ...data
      },
      updatedInvoiceId: data?.id,
      message: "success",
    };


    return respData;
  } catch (error) {
    console.log("error of update purchase", error.message);
    return {
      message: "error",
    };
  }
});

export const deletePurchase = createAsyncThunk(
  "purchase/deletePurchase",
  async ({ id, deleteSuccessCallBack }) => {
    try {
      const resp = await axios({
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `purchase-invoice/${id}`,
        data: {
          status: false,
        },
      });
      deleteSuccessCallBack()
      return resp.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const restorePurchase = createAsyncThunk("purchase/restorePurchase", async ({id, restoreSuccessCallBack }) => {
	try {
		const resp = await axios({
			method: "delete",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `purchase-invoice/${id}`,
			data: {
				status: true,
			},
		});
		restoreSuccessCallBack()
		return resp.data;
	} catch (error) {
		console.log(error.message);
	}
}
);

export const loadSinglePurchase = createAsyncThunk(
  "purchase/loadSinglePurchase",
  async (id) => {
    try {
      const data = await axios.get(`purchase-invoice/${id}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const loadAllPurchase = createAsyncThunk(
  "purchase/loadAllPurchase",
  async (arg) => {
    try {
      const query = queryGenerator(arg);
      const { data } = await axios.get(`purchase-invoice?${query}`);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);


export const addSupplierPaymentReceipt = createAsyncThunk(
  "purchase/addPaymentReceipt",
  async (values) => {
    try {
      const { data } = await axios({
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `supplier/payment-receipt`,
        data: {
          ...values,
        },
      });

      toast.success("New Payment added");
      const respData = {
        data,
        message: "success",
      };

      return respData;
    } catch (error) {
      console.log(error.message);
      return {
        message: "error",
      };
    }
  }
);
export const getPurchaseListByProducts = createAsyncThunk("purchase-invoice/purchaseListByProducts", async (arg) => {
  try {
    const query = commonQueryGenerator(arg);
    const { data } = await axios.get(`purchase-invoice?query=purchaseListByProducts&${query}`);
    const respData = {
      ...data,
      message: "success",
    };
    return respData;
  } catch (error) {
    console.log(error.message);
    toast.error("Error in loading purchaseListByProducts");
  }
});

const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    clearPurchase: (state) => {
      state.purchase = null;
    },
  },
  extraReducers: (builder) => {
    // 1) ====== builders for loadAllPurchase ======

    builder.addCase(loadAllPurchase.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadAllPurchase.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload?.allPurchaseInvoice;
      state.total = action.payload?.aggregations?._count?.id;
      state.information = action.payload?.aggregations?._sum;
    });

    builder.addCase(loadAllPurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 2) ====== builders for addPurchase ======

    builder.addCase(addPurchase.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addPurchase.fulfilled, (state, action) => {
      state.loading = false;

      if (!Array.isArray(state.list)) {
        state.list = [];
      }
      const list = [...state.list];
      list.push(action.payload.newData);
      state.list = list;
    });

    builder.addCase(addPurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 3) ====== builders for loadSinglePurchase ======

    builder.addCase(loadSinglePurchase.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadSinglePurchase.fulfilled, (state, action) => {
      state.loading = false;
      state.purchase = action.payload?.data;
    });

    builder.addCase(loadSinglePurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // 4) ====== builders for deletePurchase ======

    builder.addCase(deletePurchase.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deletePurchase.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deletePurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
    builder.addCase(getPurchaseListByProducts.pending, (state) => {
      state.purchaseListByProductsLoading = true;
    });

    builder.addCase(getPurchaseListByProducts.fulfilled, (state, action) => {
      state.purchaseListByProductsLoading = false;
      state.purchaseListByProducts = action.payload?.purchaseListByProducts;
    });

    builder.addCase(getPurchaseListByProducts.rejected, (state, action) => {
      state.purchaseListByProductsLoading = false;
      state.purchaseListByProductsError = action.payload.message;
    });
  },
});

export default purchaseSlice.reducer;
export const { clearPurchase } = purchaseSlice.actions;
