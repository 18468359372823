import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator from "./../../../../utils/queryGenarator";
const initialState = {
	list: null,
	doctor: null,
	total: null,
	error: "",
	loading: false,
};

export const addDoctor = createAsyncThunk(
	"doctor/addDoctor",
	async (values) => {
		try {
			const { data } = await axios({
				method: "post",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `doctor`,
				data: {
					...values,
				},
			});
			const respData = {
				...data,
				message: "success",
			};
			toast.success("Register Successfully Done and Sent Email ");
			return respData;
		} catch (error) {
			toast.error("Error in register please try again !");
			console.log(error.message);
		}
	}
);

export const deleteDoctor = createAsyncThunk(
	"doctor/deleteDoctor",
	async ({id, deleteSuccessCallBack}) => {
		try {
			const resp = await axios({
				method: "patch",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `doctor/${id}`,
				data: {
					status: false,
				},
			});
			if (deleteSuccessCallBack) deleteSuccessCallBack()
			return resp.data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const restoreDoctor = createAsyncThunk(
	"doctor/restoreDoctor",
	async ({id, restoreSuccessCallBack}) => {
		try {
			const resp = await axios({
				method: "patch",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `doctor/${id}`,
				data: {
					status: true,
				},
			});
			if (restoreSuccessCallBack) restoreSuccessCallBack()
			return resp.data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const loadSingleDoctor = createAsyncThunk(
	"doctor/loadSingleDoctor",
	async (id) => {
		try {
			// Update the endpoint to call the doctor API
			const { data } = await axios.get(`http://localhost:5000/doctor/${id}`);
					return data; // Return the data from the doctor API
		} catch (error) {
			console.log(error.message);
		}
	}
);



export const loadAllDoctor = createAsyncThunk(
	"doctor/loadAllDoctor",
	async (arg) => {
		try {
			const query = queryGenerator(arg);
			const { data } = await axios.get(`doctor?${query}`);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);



const doctorSlice = createSlice({
	name: "doctor",
	initialState,
	reducers: {
		clearDoctor: (state) => {
			state.doctor = null;
		},
	},
	extraReducers: (builder) => {
		// 1) ====== builders for loadAllDoctor ======

		builder.addCase(loadAllDoctor.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadAllDoctor.fulfilled, (state, action) => {
			state.loading = false;
			state.list = action.payload?.getAllDoctor;
			state.total = action.payload?.totalDoctor;
		});

		builder.addCase(loadAllDoctor.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 2) ====== builders for addDoctor ======

		builder.addCase(addDoctor.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addDoctor.fulfilled, (state, action) => {
			state.loading = false;
			state.doctor = action.payload.data;
		});

		builder.addCase(addDoctor.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for loadSingleDoctor ======

		builder.addCase(loadSingleDoctor.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadSingleDoctor.fulfilled, (state, action) => {
			state.loading = false;
			state.doctor = action.payload;

		});

		builder.addCase(loadSingleDoctor.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for loadSingleDoctorEcom ======


		// 4) ====== builders for deleteDoctor ======

		builder.addCase(deleteDoctor.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(deleteDoctor.fulfilled, (state, action) => {
			state.loading = false;

			const filterDoctor = state.list.filter(
				(cust) => cust.id !== parseInt(action.payload.id) && cust
			);

			state.list = filterDoctor;
		});

		builder.addCase(deleteDoctor.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});
	},
});

export const getAllDoctorWithDueAmount = createAsyncThunk(
	"doctor/allDoctorWithDueAmount",
	async () => {
		try {
			const { data } = await axios.get(`doctor?query=allDoctorWithDueAmount`);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export default doctorSlice.reducer;
export const { clearDoctor } = doctorSlice.actions;
