import { Alert, Button, Card, Col, Form, Input, InputNumber, Row, Typography, Select } from "antd";

import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  addCustomer,
  loadAllCustomer,
} from "../../redux/rtk/features/customer/customerSlice";
import styles from "./AddCust.module.css";
import { getCustomerSetting } from "../../api/getSettings";
import getAllStates from "../../api/getStates";
import Loader from "../loader/loader";
import _ from "lodash";

const AddCust = ({ drawer, onSuccess }) => {
  const dispatch = useDispatch();
  const { Title } = Typography;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [customerSettings, setCustomerSettings] = useState({});
  const [states, setStates] = useState([]);
  //check the page url has customer on it or not
  const isAdmin = window.location.href.includes("admin");

  useEffect(() => {
    getCustomerSetting().then((data) => setCustomerSettings(data?.result));
    getAllStates().then((data) => setStates(data));
}, []);

  const onClick = () => {
    setLoading(true);
  };

  const onFinish = async (values) => {
    try {
      const resp = await dispatch(addCustomer(values));
      if (resp.payload?.message === "success") {
        setSuccess(true);
        form.resetFields();
        dispatch(loadAllCustomer({ page: 1, count: 100000, status: true }));
        if (onSuccess) {
          onSuccess();
        } else {
          window.location.reload();
        }
        //redirect to customer login page
        // wait for 5 sec and then redirect to home
        if (isAdmin !== true) {
          setTimeout(() => {
            window.location.href = "/customer/login";
            setSuccess(false);
            setLoading(false);
          }, 5000);
        } else {
          setSuccess(false);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Row className='mr-top flex justify-center' gutter={[0, 30]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={drawer ? 22 : 22}
          xl={drawer ? 22 : 22}
          className='rounded column-design '
        >
          <div
            bordered={false}
            className={` px-3 pb-5 ${isAdmin !== true ? "border-solid border-2 border-black-300 mt-[5rem]" : ""}`}
                >
          
            {success && (
              <Alert
                message='We have sent you an email with password .'
                description='Please check your email and login to your account.'
                type='success'
                showIcon
              />
            )}
            <Title level={4} className='mb-4 mt-8 text-center'>
              Register Now
            </Title>
            {
              !_.isEmpty(customerSettings) ?
                <Form
                  form={form}
                  name='basic'
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete='off'
                >
                  {
                    customerSettings.showName &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Customer/Bussiness Name'
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: "Please input Customer/Bussiness Name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings.showAge &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Age'
                      name='age'
                      rules={[
                        {
                          required: false,
                          message: "Please input Age!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings?.showReferredBy &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Doctor'
                      name='referBy'
                      rules={[
                        {
                          required: false,
                          message: "Please input  Doctor!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings.showEmail &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Email'
                      name='email'
                      rules={[
                        {
                          required: false,
                          message: "Please input  email!",
                        },
                      ]}
                    >
                      <Input type='email' />
                    </Form.Item>
                  }
                  {
                    customerSettings.showPhone &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Phone'
                      name='phone'
                      rules={[
                        {
                          required: false,
                          message: "Please input Phone!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings.showBusinessName &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Contact Person Name'
                      name='business_name'
                      rules={[
                        {
                          required: false,
                          message: "Please input Contact Person Name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings.showGstNo &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='GST No'
                      name='gst_no'
                      rules={[
                        {
                          required: false,
                          message: "Please input GST No!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings.showAddress &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Address'
                      name='address'
                      rules={[
                        {
                          required: false,
                          message: "Please input Address!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  }
                  {
                    customerSettings.showState &&
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='State'
                      name='state'
                      rules={[
                        {
                          required: true,
                          message: "Please input State!",
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          placeholder='Select a State'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                      >
                        {states.map((state) => (
                          <Select.Option key={state.id} value={state.name}>
                            {state.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  {
                    customerSettings.showOpenBalance &&
                    <Form.Item
                      label='Open Balance'
                      name='openBalance'
                    >
                      <InputNumber />
                    </Form.Item>
                  }
                  {/* <Form.Item
                style={{ marginBottom: "10px" }}
                label='Vehicle Number'
                name='vehicle_number'
                rules={[
                  {
                    required: false,
                    message: "Please input Vechile Number!",
                  },
                ]}
              >
                <Input />
              </Form.Item> */}


                  {/* <Form.Item
                style={{ marginBottom: "10px" }}
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input  password!",
                  },
                ]}>
                <Input type="password" />
              </Form.Item>

               Customer due droped */}
              <div className="md:flex md:justify-center md:items-center md:ml-12 md:gap-6">
                  <Form.Item
                    className={styles.addCustomerBtnContainer}
                  >
                    <Button
                      onClick={onClick}
                      loading={loading}
                      type='primary'
                      htmlType='submit'
                      shape='round'
                    >
                      Register Now
                    </Button>
                  </Form.Item>
              
                  <Form.Item
                    className={styles.addCustomerBtnContainer}
                  >
                     <NavLink to='/admin/customer-setting' onClick={()=> setTimeout(() => {
                      window.location.reload()
                     }, 2000)}>
                         <span className="w-60 text-sky-300 underline underline-offset-2 border border-sky-500 rounded-lg hover:text-sky-600 p-2">CustomerSettings</span>
                    </NavLink>
                  </Form.Item>
              </div>
             </Form> : <Loader />
            }
            
            {!isAdmin && (
              <Title className='mt-5 mb-5 text-center text-base'>
                Already have an account?{" "}
                <Link to='/customer/login'>Login Now</Link>
              </Title>
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddCust;
