import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator from "../../../../utils/queryGenarator";
import _ from "lodash";
import { commonQueryGenerator } from "../../../../utils/queryGenarator";

const initialState = {
	list: null,
	sale: null,
	total: null,
	error: "",
	loading: false,
	allSaleProductsLoading: false,
	allSaleProducts: null,
	allSaleProductsError: '',
	defaultManualId: '',
	draftList: [],
	draftListLoading: false,
};

export const addSale = createAsyncThunk("sale/addSale", async (values) => {
	try {
		const { data } = await axios({
			method: "post",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `sale-invoice/`,
			data: {
				...values,
			},
		});
		const respData = {
			newData: {
				...data?.createdInvoice,
				customer: data?.customer,
			},
			createdInvoiceId: data?.createdInvoice?.id,
			message: "success",
			warningMessages:data?.warningMessages
		};


		return respData;
	} catch (error) {
		console.log("error of add sale", error.message);
		return {
			message: error?.response?.data?.message || "error",
		};
	}
});

// Ecomerce Add Order

export const addEcomOrder = createAsyncThunk(
	"sale/addEcomOrder",
	async (values) => {
		try {
			const { data } = await axios({
				method: "post",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `e-commerce/orders/create-order`,
				data: {
					...values,
				},
			});

			const respData = {
				newData: {
					...data.createdInvoice,
					customer: data.customer,
				},
				createdInvoiceId: data.createdInvoice.id,
				message: "success",
			};

			return respData;
		} catch (error) {
			console.log(error.message);
			return {
				message: "error",
			};
		}
	}
);

export const deleteSale = createAsyncThunk("sale/deleteSale", async ({ id, deleteSuccessCallBack }) => {
	try {
		const resp = await axios({
			method: "delete",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `sale-invoice/${id}`,
			data: {
				status: false,
			},
		});
		deleteSuccessCallBack()
		return resp.data;
	} catch (error) {
		console.log(error.message);
	}
});

export const loadSingleSale = createAsyncThunk(
	"sale/loadSingleSale",
	async (id) => {
		try {
			const data = await axios.get(`sale-invoice/${id}`);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const loadAllSale = createAsyncThunk("sale/loadAllSale", async (arg) => {
	try {
		const query = queryGenerator(arg);
		const { data } = await axios.get(`sale-invoice?${query}`);

		const respData = {
			...data,
			message: "success",
		};
		return respData;
	} catch (error) {
		console.log(error.message);
		toast.error("Error in loading sales");
	}
});

// Load all ecom orders

export const getAllSale = createAsyncThunk("sale/getAllSale", async (arg) => {
	try {
		const { data } = await axios.get(`sale-invoice?query=allSaleList`);
		return data;
	} catch (error) {
		toast.error("Error in loading sales");
	}
});

export const loadAllEcomOrders = createAsyncThunk(
	"sale/loadAllEcomOrders",
	async () => {
		// fetching id from local storage
		const id = localStorage.getItem("id");
		try {
			const { data } = await axios.get(`e-commerce/orders/order-details/${id}`);

			const respData = {
				...data,
			};

			return respData;
		} catch (error) {
			console.log(error.message);
			toast.error("Something went wrong, please try again later");
		}
	}
);

export const updateSale = createAsyncThunk("sale/updateSale", async ({ values, saleId }) => {
	try {
		const { data } = await axios({
			method: "put",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `sale-invoice/${saleId}`,
			data: {
				...values,
			},
		});
		const respData = {
			udpatedData: {
				...data
			},
			updatedInvoiceId: data?.id,
			message: "success",
		};


		return respData;
	} catch (error) {
		console.log("error of add sale", error.message);
		return error.response;
	}
});

export const getAllSaleProducts = createAsyncThunk("sale-invoice/allSaleProducts", async (arg) => {
	try {
		const query = commonQueryGenerator(arg);
		const { data } = await axios.get(`sale-invoice?query=allSaleProducts&${query}`);
		const respData = {
			...data,
			message: "success",
		};
		return respData;
	} catch (error) {
		console.log(error.message);
		toast.error("Error in loading allSaleProducts");
	}
});

export const loadDefaultManualId = createAsyncThunk(
	"sale/loadDefaultManualId",
	async (id) => {
		try {
			const data = await axios.get(`sale-invoice/get/default-manual-id`);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const addSaleInvoiceDraft = createAsyncThunk("sale/addSaleInvoiceDraft", async (values) => {
	try {
		const { data } = await axios({
			method: "post",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `sale-invoice-draft/`,
			data: {
				...values,
			},
		});
		const respData = {
			data,
			message: "success",
		};
		return respData;
	} catch (error) {
		console.log("error of add sale", error.message);
		return {
			message: error?.response?.data?.message || "error",
		};
	}
});

export const getAllSaleInvoiceDrafts = createAsyncThunk("sale/getAllSaleInvoiceDrafts", async () => {
	try {
		const { data } = await axios.get(`sale-invoice-draft`);
		return data;
	} catch (error) {
		toast.error("Error in loading sales");
	}
});

export const deleteSaleInvoiceDraft = createAsyncThunk("sale/deleteSaleInvoiceDraft", async ({ id, deleteSuccessCallBack }) => {
	try {
		const resp = await axios({
			method: "delete",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `sale-invoice-draft/${id}`,
		});
		_.isFunction(deleteSuccessCallBack) && deleteSuccessCallBack();
		return resp.data;
	} catch (error) {
		console.log(error.message);
	}
});


export const updateSaleInvoiceDraft = createAsyncThunk("sale/updateSaleInvoiceDraft", async ({ values, draftId }) => {
	try {
		const { data } = await axios({
			method: "put",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json;charset=UTF-8",
			},
			url: `sale-invoice-draft/${draftId}`,
			data: values,
		});
		const respData = {
			data,
			message: "success",
		};

		return respData;
	} catch (error) {
		console.log("error of add sale", error.message);
		return error.response;
	}
});

const saleSlice = createSlice({
	name: "sale",
	initialState,
	reducers: {
		clearSale: (state) => {
			state.sale = null;
		},
	},
	extraReducers: (builder) => {
		// 1) ====== builders for loadAllSale ======

		builder.addCase(loadAllSale.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadAllSale.fulfilled, (state, action) => {
			state.loading = false;
			state.list = action.payload?.allSaleInvoice;
			state.total = action.payload?.aggregations;
		});

		builder.addCase(loadAllSale.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		builder.addCase(getAllSale.fulfilled, (state, action) => {
			state.totalList = action.payload?.allSaleInvoices;
		});

		builder.addCase(getAllSaleInvoiceDrafts.fulfilled, (state, action) => {
			state.draftList = action.payload?.saleInvoiceDrafts;
			state.draftListLoading = false;
		});
		builder.addCase(getAllSaleInvoiceDrafts.pending, (state, action) => {
			state.draftListLoading = true;
		});
		builder.addCase(getAllSaleInvoiceDrafts.rejected, (state, action) => {
			state.draftListLoading = false;
		});

		// 2) ====== builders for ecomorders ======

		builder.addCase(loadAllEcomOrders.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadAllEcomOrders.fulfilled, (state, action) => {
			state.loading = false;
			state.list = action.payload;
			state.total = action.payload.aggregations;
		});

		builder.addCase(loadAllEcomOrders.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 2) ====== builders for addSale ======

		builder.addCase(addSale.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addSale.fulfilled, (state, action) => {
			state.loading = false;
		});

		builder.addCase(addSale.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		//  ====== builders for addEcomOrder ======

		builder.addCase(addEcomOrder.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(addEcomOrder.fulfilled, (state, action) => {
			state.loading = false;

			if (!Array.isArray(state.list)) {
				state.list = [];
			}
			const list = [...state.list];
			list.push(action.payload.newData);
			state.list = list;
		});

		builder.addCase(addEcomOrder.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 3) ====== builders for loadSingleSale ======

		builder.addCase(loadSingleSale.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(loadSingleSale.fulfilled, (state, action) => {
			state.loading = false;
			state.sale = _.get(action, "payload.data", {});
		});

		builder.addCase(loadSingleSale.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});

		// 4) ====== builders for deleteSale ======

		builder.addCase(deleteSale.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(deleteSale.fulfilled, (state, action) => {
			state.loading = false;
		});

		builder.addCase(deleteSale.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		});


		builder.addCase(getAllSaleProducts.pending, (state) => {
			state.allSaleProductsLoading = true;
		});

		builder.addCase(getAllSaleProducts.fulfilled, (state, action) => {
			state.allSaleProductsLoading = false;
			state.allSaleProducts = action.payload?.allSaleProducts;
		});

		builder.addCase(getAllSaleProducts.rejected, (state, action) => {
			state.allSaleProductsLoading = false;
			state.allSaleProductsError = action.payload.message;
		});
		builder.addCase(loadDefaultManualId.fulfilled, (state, action) => {
			state.defaultManualId = action.payload.data?.manualId;
		})
		.addCase(addSaleInvoiceDraft.fulfilled, (state, action) => {
		})
	},
});

export default saleSlice.reducer;
export const { clearSale } = saleSlice.actions;
