import { Alert, Button, Col, Form, Input, InputNumber, Row, Typography, Select, Switch } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCustomerSetting } from "../../api/getSettings";
import getAllStates from "../../api/getStates";

//Update customer API REQ
export const updateDoctor = async (id, values) => {
  try {
    await axios({
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      url: `doctor/${id}`,
      data: {
        ...values,
      },
    });
    return "success";
    // return data;
  } catch (error) {
    console.log(error.message);
  }
};

function UpdateDoc() {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const [customerSettings, setCustomerSettings] = useState({});
  const [states, setStates] = useState([]);

  useEffect(() => {
    getCustomerSetting().then((data) => setCustomerSettings(data?.result));
    getAllStates().then((data) => setStates(data));
  }, []);

  //Loading Old data from URL
  const location = useLocation();
  const { data } = location.state;

  const cust = data;
  const [initValues, setInitValues] = useState({
    name: cust.name,
    age: cust.age,
    referBy: cust.referBy,
    business_name: cust.business_name,
    gst_no: cust.gst_no,
    state: cust.state,
    email: cust.email,
    phone: cust.phone,
    address: cust.address,
    dueAmount: cust.dueAmount,
    vehicle_number: cust.vehicle_number,
    openBalance: cust.openBalance
  });

  const onFinish = (values) => {
    try {
      updateDoctor(id, values);
      setSuccess(true);
      toast.success("Doctor details is updated");
      setInitValues({});
      window.history.back();
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <>
      <div className='text-center'>
        <div className='flex justify-center'>
          <Row className='mr-top'>
            <Col
              xs={25}
              sm={25}
              md={18}
              lg={32}
              xl={21}
              className='border rounded column-design'
            >
              {success && (
                <div>
                  <Alert
                    message={`Doctor details updated successfully`}
                    type='success'
                    closable={true}
                    showIcon
                  />
                </div>
              )}
              <Title level={3} className='m-4 text-center'>
                Edit Doctor Form
              </Title>
              <Form
                initialValues={{
                  ...initValues,
                }}
                form={form}
                className='m-6 mr-36 lg:mr-40'
                name='basic'
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
              >
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  fields={[{ name: "Name" }]}
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Please input customer or BusinessName!',
                    },
                  ]}
                >
                  <Input className="w-72" />
                </Form.Item>
                {/* {customerSettings.showAge &&
                <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Age'
                      name='age'
                      rules={[
                        {
                          required: false,
                          message: "Please input Age!",
                        },
                      ]}
                    >
                      <Input className="w-72"/>
                </Form.Item>
              } */}
                {/* {customerSettings?.showReferredBy &&
                <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Doctor'
                      name='referBy'
                      rules={[
                        {
                          required: false,
                          message: "Please input  Doctor!",
                        },
                      ]}
                    >
                      <Input className="w-72" />
                </Form.Item>
              } */}
                {
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label='Email'
                    name='email'
                    rules={[
                      {
                        required: false,
                        message: "Please input  email!",
                      },
                    ]}
                  >
                    <Input type='email' className="w-72" />
                  </Form.Item>
                }
                {
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label='Phone'
                    name='phone'
                    rules={[
                      {
                        required: false,
                        message: "Please input customer Phone!",
                      },
                    ]}
                  >
                    <Input className="w-72" />
                  </Form.Item>
                }
                {/* {customerSettings.showBusinessName &&
                <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='Contact Person Name'
                      name='business_name'
                      rules={[
                        {
                          required: false,
                          message: "Please input Contact Person Name!",
                        },
                      ]}
                    >
                      <Input className="w-72"/>
                </Form.Item>
              } */}
                {/* {customerSettings.showGstNo &&
                <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='GST No'
                      name='gst_no'
                      rules={[
                        {
                          required: false,
                          message: "Please input GST No!",
                        },
                      ]}
                    >
                      <Input className="w-72"/>
                </Form.Item>
              } */}
                {
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    label='Address'
                    name='address'
                    rules={[
                      {
                        required: false,
                        message: "Please input customer Address!",
                      },
                    ]}
                  >
                    <Input className="w-72" />
                  </Form.Item>
                }
                {/* {
                <Form.Item
                      style={{ marginBottom: "10px" }}
                      label='State'
                      name='status'
                      rules={[
                        {
                          required: true,
                          message: "Please input State!",
                        },
                      ]}
                    >
                        <Select
                          className="w-72 text-left"
                          showSearch
                          placeholder='Select a State'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                      >
                        {states.map((state) => (
                          <Select.Option key={state.id} value={state.name}>
                            {state.name}
                          </Select.Option>
                        ))}
                      </Select>
                </Form.Item>
              } */}
                {/* <Form.Item
                  style={{ marginBottom: "10px" }}
                  fields={[{vehicle_number : "Vehicle Number" }]}
                  label='Vehicle Number'
                  name='vehicle_number'
                  rules={[
                    {
                      required: false,
                      message: "Please input customer vehicle number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}
                {/* {customerSettings.showOpenBalance &&
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  label='Open Balance'
                  name='openBalance'
                  rules={[
                    {
                      required: false,
                      message: "Please input customer Open Balance!",
                    },
                  ]}
                >
                  <InputNumber className="w-72"/>
                </Form.Item>
              } */}
                {/* <Form.Item
                  label='Status'
                  name='status'
                  initialValue={false}
                >
                  <Switch />
                </Form.Item> */}
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button block type='primary' htmlType='submit' shape='round'>
                    Update Now
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default UpdateDoc;
