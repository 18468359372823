import { DeleteOutlined, EditOutlined, SolutionOutlined } from "@ant-design/icons";
import { Button, Card, Popover, Typography } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import Loader from "../loader/loader";
import { loadSingleDoctor , clearDoctor , deleteDoctor } from "../../redux/rtk/features/doctor/doctorSlice";

//PopUp

const DetailDoc = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  //dispatch
  const dispatch = useDispatch();
  const doctor = useSelector((state) => state.doctor.doctor);
  console.log("doctor" ,doctor)

  //Delete Supplier
  const onDelete = () => {
    try {
      dispatch(deleteDoctor({id}));

      setVisible(false);
      toast.warning(`doctor : ${doctor.name} is removed `);
      return navigate("/admin/doctor");
    } catch (error) {
      console.log(error.message);
    }
  };
  // Delete Supplier PopUp
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    dispatch(loadSingleDoctor(id));
    return () => {
      dispatch(clearDoctor());
    };
  }, [id]);

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <div className='mr-top'>
        {doctor ? (
          <Fragment key={doctor?.id}>
            <Card bordered={false} style={{}}>
              <div className='flex justify-between m-3'>
                <h5>
                  <SolutionOutlined />
                  <span className='mr-left'>
                    ID : {doctor.manualId} | {doctor.name}
                  </span>
                </h5>
                <div className='text-end'>
                  <Link
                    className='m-2'
                    to={`/admin/doctor/${doctor.id}/update`}
                    state={{ data: doctor }}
                  >
                    <Tooltip title="Edit">
                    <Button
                      type='primary'
                      shape='round'
                      icon={<EditOutlined />}
                    ></Button>
                    </Tooltip>
                  </Link>
                  <Popover
                    content={
                      <a onClick={onDelete}>
                        <Button type='primary' danger>
                          Yes Please !
                        </Button>
                      </a>
                    }
                    title='Are you sure you want to delete ?'
                    trigger='click'
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                  ><Tooltip title="Delete">
                    <Button
                      type='danger'
                      DetailDoc
                      shape='round'
                      icon={<DeleteOutlined />}
                    ></Button>
                    </Tooltip>
                  </Popover>
                </div>
              </div>
              <div className='card-body m-2 space-y-3'>
                <p>
                  <Typography.Text strong>Phone Number :</Typography.Text>{" "}
                  {doctor.phone}
                </p>
                <p>
                  <Typography.Text strong>Email :</Typography.Text>{" "}
                  {doctor.email}
                </p>

                <p>
                  <Typography.Text strong>Address :</Typography.Text>{" "}
                  {doctor.address}
                </p>

              
              </div>

            
             
            </Card>
          </Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default DetailDoc;
