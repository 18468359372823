
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator from "../../../../utils/queryGenarator";

const initialState = {
  list: [],
  vendor: null,
  error: "",
  loading: false,
  total: 0,
};

export const addVendor = createAsyncThunk("vendor/addVendor", async (values) => {
  try {
    const { data } = await axios.post(`vendor`, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    toast.success("Vendor registration successful");
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Failed to add vendor");
    throw error;
  }
});

export const deleteVendor = createAsyncThunk("vendor/deleteVendor", async (id) => {
  try {
    const { data } = await axios.patch(`vendor/${id}`, { status: false }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return id;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
});

export const restoreVendor = createAsyncThunk(
	"vendor/restoreVendor",
	async ({id, restoreSuccessCallBack}) => {
		try {
			const resp = await axios({
				method: "patch",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json;charset=UTF-8",
				},
				url: `vendor/${id}`,
				data: {
					status: true,
				},
			});
			if (restoreSuccessCallBack) restoreSuccessCallBack()
			return resp.data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const loadSingleVendor = createAsyncThunk("vendor/loadSingleVendor", async (id) => {
  try {
    const { data } = await axios.get(`vendor/${id}`);
    return data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
});

export const loadAllVendor = createAsyncThunk("vendor/loadAllVendor", async (arg) => {
  try {
    const query = queryGenerator(arg);
    const { data } = await axios.get(`vendor?${query}`);
    return data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
});

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    clearVendor: (state) => {
      state.vendor = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAllVendor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadAllVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.list = (action.payload.getAllVendor || []).sort((a, b) => b.id - a.id);
      state.total = action.payload.totalVendor || 0;
    });
    builder.addCase(loadAllVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(addVendor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.list = [...state.list, action.payload];
    });
    builder.addCase(addVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(loadSingleVendor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadSingleVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.vendor = action.payload;
    });
    builder.addCase(loadSingleVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteVendor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.list = state.list.filter(vendor => vendor.id !== parseInt(action.payload));
    });
    builder.addCase(deleteVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default vendorSlice.reducer;
export const { clearVendor } = vendorSlice.actions;
