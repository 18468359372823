import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator, { commonQueryGenerator } from "../../../../utils/queryGenarator";
import _ from "lodash";

const initialState = {
    list: null,
    quotation: null,
    total: null,
    error: "",
    loading: false,
    quotationsAndProductsLoading: false,
    quotationsAndProducts: null,
    quotationsAndProductsError: ''
};

export const addQuotation = createAsyncThunk("quotation/addQuotation", async (values) => {
    try {
        const { data } = await axios({
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            url: `quotation/`,
            data: {
                ...values,
            },
        });

        const respData = {
            newData: {
                ...data?.createdInvoice,
                customer: data?.customer,
            },
            createdInvoiceId: data?.createdInvoice?.id,
            message: "success",
        };


        return respData;
    } catch (error) {
        console.log("error of add quotation", error.message);
        return {
            message: "error",
        };
    }
});

// Ecomerce Add Order

export const addEcomOrder = createAsyncThunk(
    "quotation/addEcomOrder",
    async (values) => {
        try {
            const { data } = await axios({
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                url: `e-commerce/orders/create-order`,
                data: {
                    ...values,
                },
            });

            const respData = {
                newData: {
                    ...data.createdInvoice,
                    customer: data.customer,
                },
                createdInvoiceId: data.createdInvoice.id,
                message: "success",
            };

            return respData;
        } catch (error) {
            console.log(error.message);
            return {
                message: "error",
            };
        }
    }
);

export const deleteQuotation = createAsyncThunk("quotation/deleteQuotation", async ({ id, deleteSuccessCallBack }) => {
    try {
        const resp = await axios({
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            url: `quotation/${id}`,
            data: {
                status: false,
            },
        });
        deleteSuccessCallBack()
        return resp.data;
    } catch (error) {
        console.log(error.message);
    }
});

export const restoreQuotation = createAsyncThunk("quotation/restoreQuotation", async ({ id, restoreSuccessCallBack }) => {
    try {
        const resp = await axios({
            method: "delete",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            url: `quotation/${id}`,
            data: {
                status: true,
            },
        });
        restoreSuccessCallBack()
        return resp.data;
    } catch (error) {
        console.log(error.message);
    }
});

export const loadSingleQuotation = createAsyncThunk(
    "quotation/loadSingleQuotation",
    async (id) => {
        try {
            const data = await axios.get(`quotation/${id}`);
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
);

export const loadAllQuotation = createAsyncThunk("quotation/loadAllQuotation", async (arg) => {
    try {
        const query = queryGenerator(arg);
        const { data } = await axios.get(`quotation?${query}`);

        const respData = {
            ...data,
            message: "success",
        };
        return respData;
    } catch (error) {
        console.log(error.message);
        toast.error("Error in loading quotations");
    }
});

// Load all ecom orders

export const getAllQuotation = createAsyncThunk("uotation/getAllQuotation", async (arg) => {
    try {
        const { data } = await axios.get(`quotation?query=allQuotationList`);
        return data;
    } catch (error) {
        toast.error("Error in loading quotations");
    }
});

export const loadAllEcomOrders = createAsyncThunk(
    "quotation/loadAllEcomOrders",
    async () => {
        // fetching id from local storage
        const id = localStorage.getItem("id");
        try {
            const { data } = await axios.get(`e-commerce/orders/order-details/${id}`);

            const respData = {
                ...data,
            };

            return respData;
        } catch (error) {
            console.log(error.message);
            toast.error("Something went wrong, please try again later");
        }
    }
);

export const updateQuotation = createAsyncThunk("quotation/updateQuotation", async ({ values, quotationId }) => {
    try {
        const { data } = await axios({
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            url: `quotation/${quotationId}`,
            data: {
                ...values,
            },
        });
        const respData = {
            udpatedData: {
                ...data
            },
            updatedInvoiceId: data?.id,
            message: "success",
        };


        return respData;
    } catch (error) {
        console.log("error of add quotation", error.message);
        return {
            message: "error",
        };
    }
});

export const quotationListByProducts = createAsyncThunk("quotation/quotationListByProducts", async (arg) => {
    try {
        const query = commonQueryGenerator(arg);
        const { data } = await axios.get(`quotation?query=quotationListByProducts&${query}`);

        const respData = {
            ...data,
            message: "success",
        };
        return respData;
    } catch (error) {
        console.log(error.message);
        toast.error("Error in loading quotationListByProducts");
    }
});


const quotaionSlice = createSlice({
    name: "quotation",
    initialState,
    reducers: {
        clearQuotation: (state) => {
            state.quotation = null;
        },
    },
    extraReducers: (builder) => {
        // 1) ====== builders for loadAllQuotation ======

        builder.addCase(loadAllQuotation.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllQuotation.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload?.allQuotation;
            state.total = action.payload?.aggregations;
        });

        builder.addCase(loadAllQuotation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(getAllQuotation.fulfilled, (state, action) => {
            state.totalList = action.payload?.allQuotation;
        });

        // 2) ====== builders for ecomorders ======

        builder.addCase(loadAllEcomOrders.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadAllEcomOrders.fulfilled, (state, action) => {
            state.loading = false;
            state.list = action.payload;
            state.total = action.payload.aggregations;
        });

        builder.addCase(loadAllEcomOrders.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 2) ====== builders for addQuotation ======

        builder.addCase(addQuotation.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addQuotation.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(addQuotation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        //  ====== builders for addEcomOrder ======

        builder.addCase(addEcomOrder.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addEcomOrder.fulfilled, (state, action) => {
            state.loading = false;

            if (!Array.isArray(state.list)) {
                state.list = [];
            }
            const list = [...state.list];
            list.push(action.payload.newData);
            state.list = list;
        });

        builder.addCase(addEcomOrder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 3) ====== builders for loadSingleQuotation ======

        builder.addCase(loadSingleQuotation.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loadSingleQuotation.fulfilled, (state, action) => {
            state.loading = false;
            state.quotation = _.get(action, "payload.data", {});
        });

        builder.addCase(loadSingleQuotation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        // 4) ====== builders for deleteQuotation ======

        builder.addCase(deleteQuotation.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(deleteQuotation.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(deleteQuotation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        });

        builder.addCase(quotationListByProducts.pending, (state) => {
            state.quotationsAndProductsLoading = true;
        });

        builder.addCase(quotationListByProducts.fulfilled, (state, action) => {
            state.quotationsAndProductsLoading = false;
            state.quotationsAndProducts = action.payload?.quotationsAndProducts;
        });

        builder.addCase(quotationListByProducts.rejected, (state, action) => {
            state.quotationsAndProductsLoading = false;
            state.quotationsAndProductsError = action.payload.message;
        });
    },
});

export default quotaionSlice.reducer;
export const { clearQuotation } = quotaionSlice.actions;
